<template>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="9" width="11" height="1" rx="0.5" fill="#22AD6A" />
    <rect
      x="6"
      y="5"
      width="6"
      height="1"
      transform="rotate(90 6 5)"
      fill="#22AD6A"
    />
    <rect x="4" y="8" width="3" height="3" rx="0.5" fill="#22AD6A" />
    <rect width="11" height="6" rx="0.5" fill="#22AD6A" />
  </svg>
</template>

<script>
export default {
  name: 'TerminalOn'
}
</script>
